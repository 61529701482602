import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import APIServices from '../services/APIService'
import moment from 'moment'
import { ChevronLeft } from 'lucide-react'
import { Button } from '../../components/ui/button';
import { Badge } from '../../components/ui/badge';
import NavBar from '../../components/navigation/navBar';

export default function SingleArticle(props:any) {
  const {id} = useParams()
  const [article, setArticle] = useState(Object)
  // const [relatedArticles, setRelatedArticles] = useState()
  const [image, setImage] = useState(Object)
  // const { window } = props;
  // const elementRef = useRef(null);
  // const [titleHeight, setTitleHeight] = useState(100)

  // const trigger = useScrollTrigger({
  //   target: window ? window() : undefined,
  // });

  useEffect(() => {
    const getArticle = async () => {
      const response = await APIServices.getById('posts', id)
      setArticle(response)

      const respImage = await APIServices.getById('media', response.featured_media)
      setImage(respImage.media_details.sizes)

      // const relatedPosts = await APIServices.getRelatedPosts(id)
      // setRelatedArticles(relatedPosts)
    }

    getArticle()
  }, [id])

  // useEffect(() => {
  //   setTitleHeight(elementRef.current ? elementRef.current.clientHeight);
  // }, [article])

  // CUT INGREDIENTS TO 2
  function splitArrayInHalf(arr:any) {
    const mid = Math.ceil(arr.length / 2);
    const firstHalf = arr.slice(0, mid);
    const secondHalf = arr.slice(mid);
    
    return [firstHalf, secondHalf];
  }

  return (
    <div className='mb-20'>
      <div className='bg-secondary-lighterest h-[180px] pt-10 mb-5'>
        <NavBar blog />

        <div className='container px-8 md:px-24 2xl:px-0'>
          <a href='/blog'>
            <Button variant='ghost' className='pl-1 mb-2'><ChevronLeft className="h-4 mr-2" /> Retour</Button>
          </a>
        </div>
      </div>

      <div className="container px-8 md:px-24 2xl:px-0 grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-5">
          <div className='mt-2 sticky top-10'>
            <img
              src={image?.medium ? image?.medium?.source_url : image?.full?.source_url}
              alt="Place de marché"
              className="rounded-xl object-cover w-full"
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-7">
            <div>
              {article?.categories?.map((badge:string, index:number) => (
                <Badge variant="secondary" className="mr-1" catId={badge} />
              ))}
            </div>
            <h1 className="text-5xl font-bold text-secondary-darker my-4" dangerouslySetInnerHTML={{__html: article?.title?.rendered}} />
            <div className="flex mb-6">
              <p>{moment(article?.date).format('DD/MM/YYYY')}</p>
              <p className="ml-4">{article?.yoast_head_json?.author}</p>
            </div>

            {article?.acf?.recipe &&
              <>
                <p className="text-xl mb-8">{article?.acf?.subtitle}</p>

                <h3 className="mb-2 text-2xl text-secondary-dark font-bold">Ingrédients</h3>
                <div className="grid grid-cols-2 sm:gap-4 mb-8">
                  <div className="col-span-2 sm:col-span-1">
                    <ul>
                      {splitArrayInHalf(article?.acf?.ingredients)[0].map((ing:any, index:number) => (
                        <li key={index}>
                          {ing.quantity && <span className="mr-1"><b>{ing.quantity}</b></span>}
                          <span>{ing.ingredient}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <ul>
                      {splitArrayInHalf(article?.acf?.ingredients)[1].map((ing:any, index:number) => (
                        <li key={index}>
                          {ing.quantity && <span className="mr-1"><b>{ing.quantity}</b></span>}
                          <span>{ing.ingredient}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <h3 className="mb-2 text-2xl text-secondary-dark font-bold">Étapes</h3>
              </>
            }
            <p className="text-lg" dangerouslySetInnerHTML={{__html: article?.content?.rendered}} />
        </div>
      </div>
    </div>
  )
}