import logo from "../../assets/logos/logo.svg"
import { Button } from "../ui/button"

export default function NavBar(props:any) {

  return (
    <div className="container flex justify-between items-center mb-10 px-8 md:px-24 2xl:px-0 mx-auto">
      <div className="flex items-center">
        <a href="/">
          <img
            src={logo}
            height={40}
            alt="Mullo"
          />
        </a>
      </div>
      <div className="hidden md:flex">
        {!props.buyer ? 
          <Button variant="ghost" className="mr-4" href="/acheter-local">Je suis acheteur</Button> 
          : 
          <Button variant="ghost" className="mr-4" href="/">Je suis producteur</Button>
        }
        {/* <Button variant="destructive" className="mr-4">S'inscrire</Button>
      <Button variant="secondary" className="bg-secondary-dark">Se connecter</Button> */}
        {!props.blog && <Button href="/blog" variant="ghost" className="mr-4">Notre blog</Button>}
        <Button 
          variant="secondary"
          href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteContact"
        >
          Nous contacter
        </Button>
      </div>
    </div>
  )
}