import Delivery from "../../assets/images/delivery.png"
import Order from "../../assets/images/order.png"
import Billing from "../../assets/images/billing.png"

export default function ColsExplain(props:any) {

  const test = [
    {
      img: Order,
      title: "Commande",
      subtitle: "Je commande chez un ou plusieurs producteurs de la place de marché"
    },
    {
      img: Delivery,
      title: "Livraison",
      subtitle: "Je suis livré directement à mon établissement"
    },
    {
      img: Billing,
      title: "Facture",
      subtitle: "Je paye sur facture tous les mois"
    },
  ]

  return (
    <section className="mb-24">
        <div className="grid grid-cols-3">
          {test.map((n, i) => (
            <div key={i} className="flex flex-col px-4 lg:px-8">
              <div className="h-[180px]">
                <img
                  src={n.img}
                  className="mb-4 m-auto"
                  alt={n.title}
                />
              </div>
              <h4 className="text-3xl font-bold text-center">{n.title}</h4>
              <p className="mt-2 text-center">{n.subtitle}</p>
            </div>
          ))}
        </div>
    </section>
  )
}