export default function Section(props:any) {

  return (
    <section className={`relative min-h-screen flex justify-center items-center ${props.color}`}>
      <div className={`container px-8 md:px-24 2xl:px-0 mx-auto flex-col-reverse flex space-between ${props.col ? '' : 'md:flex-row items-center'}`}>
        <div className="max-w-xl md:pr-20 flex-1">
          <h3 className={`text-4xl font-bold ${props.textColor}`}>
            {props.texts.title}
          </h3>
          <p className="text-xl mt-6">{props.texts.subTitle}</p>
        </div>

        <div className="mb-10 md:hidden">
          <img
            src={props.imgUrlMobile}
            alt=""
          />
        </div>

        <div className="hidden md:block flex-2">
          <img
            src={props.imgUrl}
            alt=""
          />
        </div>
      </div>
    </section>
  )
}