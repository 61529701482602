import Full from "../../assets/images/full.svg"
import topMobile from "../../assets/images/top-mobile.svg"
import { Button } from "../ui/button"
import NavBar from "../navigation/navBar"

export default function HomeSection(props:any) {

  return (
    <section className={`relative min-h-screen flex justify-center items-center flex-col bg-${props.color}`}>
      <NavBar buyer={props.buyer} />

      <div className="container px-8 md:px-24 2xl:px-0 mx-auto flex flex-col-reverse md:flex-row space-between items-center">
        <div className="max-w-xl md:pr-15 flex-1">
          <h1 className="text-4xl lg:text-6xl font-bold text-secondary-dark">
            Les marchés ultra locaux pour les {props.buyer ? "professionnels" : "producteurs"}
          </h1>
          <p className="text-lg md:text-2xl mt-3 md:mt-6 mb-4 md:mb-8">
            {props.buyer ? "L’accès au circuit-court n’aura jamais été aussi simple" : "Vendre en circuit-court à vos clients professionnels n’aura jamais été aussi simple"}
          </p>
          <Button 
            variant={`${props.buyer ? 'destructive' : 'secondary'}`} 
            // className={`${props.buyer ? 'bg-tertiary-dark' : 'bg-secondary-dark'}`}
            href="https://forms.fillout.com/t/v9auhK6gFYus?type=websiteTest"
          >
            {props.buyer ? "S’approvisionner en ultra local" : "Rejoindre une place de marché"}
          </Button>
        </div>

        <div className="mb-10 md:hidden">
          <img
            src={topMobile}
            className="mt-10"
            alt="Regroupement producteurs"
          />
        </div>

        <div className="hidden md:block flex-2">
          <img
            src={Full}
            className="ml-24"
            alt="Regroupement producteurs"
          />
        </div>
      </div>
    </section>
  )
}