import Section from "../components/sections";
import HomeSection from "../components/sections/home";
import groupProd from "../assets/images/group-prod.png";
import transport from "../assets/images/transport.svg";
import transportMobile from "../assets/images/transport-mobile.svg";
import buyer from "../assets/images/buyers.svg";
import buyerMobile from "../assets/images/resto-mobile.svg";
import FullCard from "../components/cards/fullCard";
import Partners from "../components/sections/partners";

export default function Home() {
  return (
    <div>
      <div className="snap-y snap-mandatory overflow-y-scroll h-screen flex-grow z-0">
        <div className="snap-always snap-center" id="page1">
          <HomeSection color="primary" />
        </div>
        <div className="snap-always snap-center" id="page2">
          <Section 
            id="2" 
            color="bg-secondary-lighter"
            textColor="text-secondary-dark"
            imgUrl={groupProd}
            imgUrlMobile={groupProd}
            texts={{
              title: "Augmentez vos ventes en réunissant vos forces",
              subTitle: "Regroupez vous sur une même boutique en ligne pour atteindre une plus grande clientèle"
            }}
          />
        </div>
        <div className="snap-always snap-center" id="page3">
          <Section 
            id="2" 
            color="bg-secondary-light" 
            textColor="text-secondary-foreground"
            imgUrl={transport}
            imgUrlMobile={transportMobile}
            texts={{
              title: "Ne vous occupez plus des livraisons laissez nous le volant",
              subTitle: "Vous n’avez plus qu’à livrer le point de collecte chez un de vos confrères au sein de votre place de marché. Et cerise sur le gateau : on prépare nous même les commandes !"
            }}
          />
        </div>
        <div className="snap-always snap-center" id="page4">
          <Section 
            id="3" 
            color="bg-primary"
            textColor="text-secondary-dark"
            imgUrl={buyer}
            imgUrlMobile={buyerMobile}
            texts={{
              title: "Mutualisez votre clientèle et augmentez votre chiffre d’affaire",
              subTitle: "Sans effort supplémentaire : à partir de cet automne on s’interface avec votre logiciel de vente favoris ! (en attendant laissez nous la main)"
            }}
            col
          />
        </div>
        <div className="snap-always snap-start" id="page5">
          <div className="container px-8 md:px-24 2xl:px-0 py-8 md:py-24">
            <FullCard />
          </div>
          <Partners />
        </div>
      </div>
    </div>
    
  )
}