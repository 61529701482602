import HomeSection from "../components/sections/home";
import FullCard from "../components/cards/fullCard";
import Partners from "../components/sections/partners";
import ColsExplain from "../components/sections/colsExplain";

export default function Buyer() {
  return (
    <div>
      <div className="snap-y snap-mandatory overflow-y-scroll h-screen flex-grow z-0">
        <div className="snap-always snap-center" id="page1">
          <HomeSection color="primary" buyer />
        </div>
        <div className="snap-always snap-start" id="page5">
          <div className="container px-8 md:px-24 2xl:px-0 py-8 md:py-24">
            <ColsExplain />

            <FullCard buyer />
          </div>
          <Partners />
        </div>
      </div>
    </div>
    
  )
}